import {CheckoutControllerProps} from '../../types/checkoutApp.types';
import {BaseController, DataExtensionsService} from '@wix/wixstores-client-storefront-sdk';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {CheckoutStore} from '../../domain/stores/checkout/CheckoutStore';
import {CheckoutSettingsStore} from '../../domain/stores/checkout/CheckoutSettingsStore';
import {CheckoutService} from '../../domain/services/checkout/CheckoutService';
import {CheckoutSettingsService} from '../../domain/services/checkout/CheckoutSettingsService';
import {NavigationService} from '../../domain/services/checkout/NavigationService';
import {NavigationStore} from '../../domain/stores/checkout/NavigationStore';
import {DeliveryMethodStore} from '../../domain/stores/checkout/DeliveryMethodStore';
import {BIService} from '../../domain/services/checkout/BIService';
import {SlotsStore} from '../../domain/stores/checkout/SlotsStore';
import {CheckboxesService} from '../../domain/services/checkout/CheckboxesService';
import {CheckboxesStore} from '../../domain/stores/checkout/CheckboxesStore';
import {ExpressCheckoutStore} from '../../domain/stores/checkout/ExpressCheckoutStore';
import {PaymentStore} from '../../domain/stores/checkout/PaymentStore';
import {FormsStore} from '../../domain/stores/checkout/FormsStore';
import {StepsManagerService} from '../../domain/services/checkout/StepsManagerService';
import {StepsManagerStore} from '../../domain/stores/checkout/StepsManagerStore';
import {withResultObserver} from '@wix/function-result-observation';
import {MemberStore} from '../../domain/stores/checkout/MemberStore';
import {MemberService} from '../../domain/services/checkout/MemberService';
import {PaymentService} from '../../domain/services/checkout/PaymentService';
import {FormsService} from '../../domain/services/checkout/FormsService';
import {toMonitorError} from '../../domain/utils/errorMonitor.utils';
import {ErrorDetailsFragment} from '../../gql/graphql';
import {type CountryCode} from '@wix/form-multiline-address';
import {ContentService} from '../../domain/services/checkout/ContentService';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {FedopsInteractions} from './constants';
import {SPECS} from '../../common/constants';
import {DeliverySectionStore} from '../../domain/stores/checkout/DeliverySectionStore';

export class CheckoutController extends BaseController {
  protected checkoutStore!: CheckoutStore;
  protected deliveryMethodStore!: DeliveryMethodStore;
  protected checkoutSettingsStore!: CheckoutSettingsStore;
  protected navigationStore!: NavigationStore;
  protected slotsStore!: SlotsStore;
  protected checkboxesStore!: CheckboxesStore;
  protected expressCheckoutStore!: ExpressCheckoutStore;
  protected paymentStore!: PaymentStore;
  protected stepsManagerStore!: StepsManagerStore;
  protected formsStore!: FormsStore;
  protected memberStore!: MemberStore;
  protected deliverySectionStore!: DeliverySectionStore;
  protected translationPromise!: Promise<Record<string, string>>;

  private initServiceFunctions!: (() => void)[];

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    if (isSSR(controllerParams.controllerConfig)) {
      return;
    }
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.CheckoutPageLoaded);
    this.setStores(controllerParams);
    this.loadLocaleDataSetTranslations(controllerParams);
  }

  private loadLocaleDataSetTranslations(controllerParams: ControllerParams) {
    this.translationPromise = this.fetchLocaleDataSetTranslations(controllerParams);
  }

  private async fetchLocaleDataSetTranslations(controllerParams: ControllerParams) {
    const {language} = controllerParams.flowAPI.translations.config;
    let translations: Record<string, string>;
    try {
      translations = await import(`@wix/locale-dataset-data/resources/translations/messages_${language}.json`);
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(e));
      translations = (await import(
        `@wix/locale-dataset-data/resources/translations/messages_en.json`
      )) as unknown as Record<string, string>;
    }
    return {...controllerParams.flowAPI.translations.all, ...translations};
  }

  private async setLocaleDatasetTranslations() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({_translations: await this.translationPromise});
  }

  private setStores({flowAPI, controllerConfig}: ControllerParams) {
    const siteStore = this.siteStore;
    const navigationService = new NavigationService({siteStore, controllerConfig, flowAPI});
    const isFastFlow = navigationService.isFastFlow;
    const currency = navigationService.currency;
    const contentAppId = navigationService.checkoutContentAppId;
    const contentComponentId = navigationService.checkoutContentComponentId;
    const contentService = new ContentService({flowAPI, siteStore, currency, contentAppId, contentComponentId});
    const dataExtensionsService = new DataExtensionsService({httpClient: flowAPI.essentials.httpClient});
    const checkoutSettingsService = new CheckoutSettingsService({
      controllerConfig,
      siteStore,
      flowAPI,
      dataExtensionsService,
      contentService,
      isFastFlow,
    });
    const checkboxesService = new CheckboxesService();
    const memberService = new MemberService({flowAPI, siteStore, currency});
    const formsService = new FormsService({siteStore, checkoutSettingsService, memberService});
    const biService = new BIService({
      siteStore,
      navigationService,
      checkoutSettingsService,
      memberService,
      formsService,
    });
    const stepsManagerService = new StepsManagerService({siteStore, biService});
    const paymentService = new PaymentService();
    const checkoutService = new CheckoutService({
      flowAPI,
      siteStore,
      biService,
      navigationService,
      checkoutSettingsService,
      currency,
    });

    const updateComponent = this.updateComponent.bind(this);
    const observe = withResultObserver(controllerConfig.setProps);

    this.checkoutStore = new CheckoutStore({
      flowAPI,
      siteStore,
      checkoutService,
      formsService,
      updateComponent,
      checkoutSettingsService,
      navigationService,
      dataExtensionsService,
      biService,
      stepsManagerService,
      memberService,
      observe,
    });

    this.deliveryMethodStore = new DeliveryMethodStore({
      flowAPI,
      siteStore,
      checkoutService,
      biService,
      updateComponent,
      navigationService,
    });

    this.checkoutSettingsStore = new CheckoutSettingsStore({
      siteStore,
      checkoutSettingsService,
    });

    this.navigationStore = new NavigationStore({
      navigationService,
      checkoutService,
      memberService,
      siteStore,
      biService,
      stepsManagerService,
      controllerConfig,
      updateComponent,
      isSSR: isSSR(controllerConfig),
    });

    this.slotsStore = new SlotsStore({
      controllerConfig,
      siteStore,
      checkoutService,
      updateComponent,
      navigationService,
      stepsManagerService,
      biService,
    });

    this.checkboxesStore = new CheckboxesStore({
      flowAPI,
      checkoutService,
      checkoutSettingsService,
      checkboxesService,
      updateComponent,
      biService,
      formsService,
      navigationService,
    });

    this.stepsManagerStore = new StepsManagerStore({
      stepsManagerService,
      biService,
      checkoutService,
      checkoutSettingsService,
      memberService,
      siteStore,
      updateComponent,
      formsService,
      navigationService,
      isSSR: isSSR(controllerConfig),
    });

    this.expressCheckoutStore = new ExpressCheckoutStore({
      controllerConfig,
      navigationService,
      checkoutService,
      paymentService,
      siteStore,
      flowAPI,
      biService,
      updateComponent,
    });

    this.paymentStore = new PaymentStore({
      flowAPI,
      checkoutService,
      checkoutSettingsService,
      navigationService,
      paymentService,
      memberService,
      observe,
      updateComponent,
    });

    this.formsStore = new FormsStore({
      flowAPI,
      navigationService,
      checkoutService,
      checkoutSettingsService,
      paymentService,
      biService,
      observe,
      formsService,
      updateFormsLoaded: () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.setProps({
          formsStore: this.formsStore.toProps(),
        });
      },
      updateComponent,
      navigateOnFailure: this.navigationStore.navigateOnFailure.bind(this.navigationStore),
    });

    this.memberStore = new MemberStore({
      flowAPI,
      memberService,
      navigationService,
      checkoutService,
      updateComponent,
      biService,
      siteStore,
    });

    this.deliverySectionStore = new DeliverySectionStore({
      flowAPI,
      controllerConfig,
      checkoutService,
    });

    this.initServiceFunctions = [
      async () => {
        await checkoutService.init();
        this.expressCheckoutStore.init();
        if (!navigationService.isFastFlow) {
          await this.memberStore.init();
        }
        if (this.flowAPI.experiments.enabled(SPECS.UseDeliverySectionFromLib)) {
          this.deliverySectionStore.init();
        }
      },
      () => checkoutSettingsService.init(),
      () => navigationService.load(),
      ...(this.flowAPI.experiments.enabled(SPECS.SupportMemberOnlyCheckout)
        ? [() => this.memberStore.setIsMemberAreaAppInstalled()]
        : []),
    ];
  }

  private readonly updateComponent = () => {
    const props: CheckoutControllerProps = {
      isLoading: false,
      checkoutStore: this.checkoutStore.toProps(),
      deliveryMethodStore: this.deliveryMethodStore.toProps(),
      checkoutSettingsStore: this.checkoutSettingsStore.toProps(),
      navigationStore: this.navigationStore.toProps(),
      slotsStore: this.slotsStore.toProps(),
      checkboxesStore: this.checkboxesStore.toProps(),
      stepsManagerStore: this.stepsManagerStore.toProps(),
      paymentStore: this.paymentStore.toProps(),
      formsStore: this.formsStore.toProps(),
      memberStore: this.memberStore.toProps(),
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps(props);
  };

  public readonly load = (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({isLoading: true, fitToContentHeight: true});
    void this.loadData();
    return Promise.resolve();
  };

  public readonly loadData = async (): Promise<void> => {
    if (this.siteStore.isSSR()) {
      return;
    }
    void this.setLocaleDatasetTranslations();
    try {
      await Promise.all(this.initServiceFunctions.map((fn) => fn()));
      await this.stepsManagerStore.initStepsManagerService();
      if (this.navigationStore.isPreselectedFlow() && this.checkoutStore.getAddress()?.country) {
        this.checkoutSettingsStore.addPreselectedFlowCountryIfNeeded(
          this.checkoutStore.getAddress()?.country as CountryCode
        );
      }

      await this.formsStore.init();
      this.checkoutStore.sendCheckoutPageLoadBIEvent();
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(e, 'CheckoutController load'));

      await this.onLoadFailure(e as {response: {data: {details: ErrorDetailsFragment}}});
      return;
    }
    this.siteStore.pubSubManager.publish('Cart.Invalidated', {});

    this.updateComponent();
  };

  private readonly onLoadFailure = async (e: {response: {data: {details: ErrorDetailsFragment}}}) => {
    await this.navigationStore.onLoadFailure(e);
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  public readonly init = async (): Promise<void> => {
    return this.load();
  };

  /* istanbul ignore next */
  public getFreeTexts(): string[] {
    return [];
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onStyleUpdate(_styleParams: /* eslint-disable @typescript-eslint/ban-types */ {}) {}
}

function isSSR(controllerConfig: IWidgetControllerConfig) {
  return controllerConfig.wixCodeApi.window.rendering.env === 'backend';
}
